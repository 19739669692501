import * as $ from 'jquery';
(<any>window).$ = (<any>window).jQuery = $;

import './../../../../../vendor/yiisoft/yii2/assets/yii.js';
import './../../../../../vendor/yiisoft/yii2/assets/yii.activeForm.js';
import './../../../../../vendor/yiisoft/yii2/assets/yii.captcha.js';
import './../../../../../vendor/yiisoft/yii2/assets/yii.gridView.js';
import './../../../../../vendor/yiisoft/yii2/assets/yii.validation.js';
import './../../../../../vendor/bower-asset/jquery-ui/jquery-ui.js';

import "./../../../../../vendor/vova07/yii2-imperavi-widget/src/assets/redactor.js";
import "./../../../../../vendor/vova07/yii2-imperavi-widget/src/assets/lang/ru";
import "./../../../../../vendor/vova07/yii2-imperavi-widget/src/assets/plugins/clips/clips";
import "./../../../../../vendor/vova07/yii2-imperavi-widget/src/assets/plugins/counter/counter";
import "./../../../../../vendor/vova07/yii2-imperavi-widget/src/assets/plugins/definedlinks/definedlinks";
import "./../../../../../vendor/vova07/yii2-imperavi-widget/src/assets/plugins/filemanager/filemanager";
import "./../../../../../vendor/vova07/yii2-imperavi-widget/src/assets/plugins/fontcolor/fontcolor";
import "./../../../../../vendor/vova07/yii2-imperavi-widget/src/assets/plugins/fontfamily/fontfamily";
import "./../../../../../vendor/vova07/yii2-imperavi-widget/src/assets/plugins/fontsize/fontsize";
import "./../../../../../vendor/vova07/yii2-imperavi-widget/src/assets/plugins/fullscreen/fullscreen";
import "./../../../../../vendor/vova07/yii2-imperavi-widget/src/assets/plugins/imagemanager/imagemanager";
import "./../../../../../vendor/vova07/yii2-imperavi-widget/src/assets/plugins/limiter/limiter";
import "./../../../../../vendor/vova07/yii2-imperavi-widget/src/assets/plugins/table/table";
import "./../../../../../vendor/vova07/yii2-imperavi-widget/src/assets/plugins/textdirection/textdirection";
import "./../../../../../vendor/vova07/yii2-imperavi-widget/src/assets/plugins/textexpander/textexpander";
import "./../../../../../vendor/vova07/yii2-imperavi-widget/src/assets/plugins/video/video";


import 'animate.css'
import './css/app.scss'
import './js/app'


